<template>
    <transition name="slide-fade" mode="out-in">
        <card-wrapper
            v-if="isMounted"
            :contentClass="[
                'nft-card__content',
                {'black-gradient': isMyCharacter}
            ]"
            :image="image"
            :web-images="web_images"
            :type="type"
            :tier="tier"
            :level="level"
            class="nft-card"
            @click="$emit('click')"
        >
            <template slot="content">
                <div class="nft-card__title">
                    {{ name }}
                </div>

                <div class="nft-card__subtitle">
                    <div>TIER: {{ tierLabel }}</div>
                    <div class="nft-card__subtitle-stats">
                        <div class="nft-card__subtitle-wins">WIN: {{wins}}</div>
                        <div>LOSE: {{losses}}</div>
                    </div>
                </div>

                <div class="nft-card-info">
                    <btn v-if="!isMyOrder"
                        type="mint-gold"
                        class="nft-card__btn"
                        text="BUY ? MF"
                        :selected="toLocaleFormat(sale_price)"
                        :hoverText="`BUY ${toLocaleFormat(sale_usd_price) || 100} $`"
                        @click="$emit('btn-click')"
                    />
                    <btn v-else
                        type="mint-gold"
                        class="nft-card__btn"
                        text="? MF"
                        :selected="toLocaleFormat(sale_price)"
                        :hoverText="`${toLocaleFormat(sale_usd_price) || 100} $`"
                        isDisabled
                    />
                </div>
            </template>
        </card-wrapper>

        <div class="bg-transparent" v-else></div>
    </transition>
</template>

<script>
import cardWrapper from './card-wrapper.vue'
import Tiers from "@/constants/Tiers.js";
import {mapGetters} from "vuex";
import {isStringsEquals} from "@/utils/helpers";

export default {
    name: "ShopNftCard",
    components: {
        cardWrapper,
    },
    props: {
        type: { type: String, default: '' },
        image: { type: String, default: '' },
        web_images: { type: Array, default: () => ([]) },
        name: { type: String, default: '' },
        sale_price: { type: [String, Number], default: '' },
        sale_usd_price: { type: [String, Number], default: '' },
        transitionDuration: { type: Number, default: 0},
        isMyCharacter: { type: Boolean, default: false },
        tier: { type: Number, default: 1 },
        level: { type: [String, Number], default: '' },
        losses: { type: [String, Number], default: '' },
        wins: { type: [String, Number], default: '' },
        seller: { type: [String, Number], default: '' },
    },
    computed: {
        ...mapGetters('auth', ['user']),
        ...mapGetters(['account']),

        isMyOrder() {
            return isStringsEquals(this.seller, this.account);
        },
    },
    data() {
        return {
            isMounted: false,
            tierLabel: Tiers[this.tier],
        }
    },
    mounted() {
        setTimeout(() => {
            this.isMounted = true;
        }, this.transitionDuration)
    }
}
</script>

<style lang="scss" scoped>

.nft-card {
    width: 243px!important;
}
.nft-card__content {
    padding-top: 10px;
    padding-bottom: 10px;

    &.black-gradient {
        background: linear-gradient(179deg, #1F213A 3.7%, #000000 96.25%);
    }
}
.nft-card__title {
    margin-top: 15px;
    font-size: 24px;
    text-align: center;
}
.nft-card__subtitle {
    margin-top: 18px;
    font: 500 14px "Tektur";
    color: #BFBFBF;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;

    &-stats {
        text-align: right;
    }
    &-wins {
        margin-bottom: 5px;
    }
}

.nft-card-info {
    transform: skew(4deg);
    padding: 10px 30px;
}

.nft-card__btn {
    margin-top: 10px;
    padding: 10px 0;
    width: 100%;
}

</style>
