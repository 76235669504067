var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "slide-fade", mode: "out-in" } },
    [
      _vm.isMounted
        ? _c(
            "card-wrapper",
            {
              staticClass: "nft-card",
              attrs: {
                contentClass: [
                  "nft-card__content",
                  { "black-gradient": _vm.isMyCharacter },
                ],
                image: _vm.image,
                "web-images": _vm.web_images,
                type: _vm.type,
                tier: _vm.tier,
                level: _vm.level,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [
              _c("template", { slot: "content" }, [
                _c("div", { staticClass: "nft-card__title" }, [
                  _vm._v(" " + _vm._s(_vm.name) + " "),
                ]),
                _c("div", { staticClass: "nft-card__subtitle" }, [
                  _c("div", [_vm._v("TIER: " + _vm._s(_vm.tierLabel))]),
                  _c("div", { staticClass: "nft-card__subtitle-stats" }, [
                    _c("div", { staticClass: "nft-card__subtitle-wins" }, [
                      _vm._v("WIN: " + _vm._s(_vm.wins)),
                    ]),
                    _c("div", [_vm._v("LOSE: " + _vm._s(_vm.losses))]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "nft-card-info" },
                  [
                    !_vm.isMyOrder
                      ? _c("btn", {
                          staticClass: "nft-card__btn",
                          attrs: {
                            type: "mint-gold",
                            text: "BUY ? MF",
                            selected: _vm.toLocaleFormat(_vm.sale_price),
                            hoverText: `BUY ${
                              _vm.toLocaleFormat(_vm.sale_usd_price) || 100
                            } $`,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("btn-click")
                            },
                          },
                        })
                      : _c("btn", {
                          staticClass: "nft-card__btn",
                          attrs: {
                            type: "mint-gold",
                            text: "? MF",
                            selected: _vm.toLocaleFormat(_vm.sale_price),
                            hoverText: `${
                              _vm.toLocaleFormat(_vm.sale_usd_price) || 100
                            } $`,
                            isDisabled: "",
                          },
                        }),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _c("div", { staticClass: "bg-transparent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }