<template>
    <div class="range">
        <div
            ref="line"
            :class="{'range__line--enabled': isEnabled}"
            class="range__line"
        >
            <input
                ref="handle0"
                type="range"
                :min="min"
                :max="max"
                v-model="sliderMin"
                :disabled="!isEnabled"
            />
            <input
                ref="handle1"
                type="range"
                :min="min"
                :max="max"
                v-model="sliderMax"
                :disabled="!isEnabled"
            />

            <i
                v-if="isEnabled"
                :style="{
                    left: `${sliderMin}%`,
                    right: `${100 - sliderMax}%`
                }"
                class="range__line-bg"
            ></i>

            <div class="range__text-wrapper">
                <span class="range__line-label">{{value[0]}}</span>
                <span class="range__line-label">{{value[1]}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Range',
    props: {
        isEnabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Array,
            default: () => [],
        },
        min: {
            type: Number,
            default: 1,
        },
        max: {
            type: Number,
            default: 100,
        },
    },
    computed: {
        sliderMin: {
            get: function () {
                return parseInt(this.value[0]);
            },
            set: function (val) {
                val = parseInt(val);

                let maxValue = this.value[1];

                if (val > maxValue) {
                    maxValue = val;
                }

                this.$emit('input', [val, maxValue]);
            }
        },
        sliderMax: {
            get: function () {
                return parseInt(this.value[1]);
            },
            set: function (val) {
                val = parseInt(val);

                let minValue = this.value[0];

                if (val < minValue) {
                    minValue = val;
                }

                this.$emit('input', [minValue, val]);
            }
        },
    },
    watch: {
        isEnabled() {
            this.$emit('input', this.value);
        }
    }
};
</script>

<style lang="scss" scoped>
    .range {
        width: 100%;

        &__line {
            width: 135px;
            height: 12px;
            background: #6B7B98;
            margin-left: auto;
            margin-right: 20px;
            position: relative;

            &-label {
                font: 500 13px "Tektur";
            }

            &-bg {
                top: 0px;
                bottom: 0px;
                position: absolute;
                background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
            }

            &--enabled {
                .range__line-handle {
                    cursor: pointer;
                    background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
                }
            }

            input[type=range] {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                -webkit-appearance: none;
            }

            input[type=range]::-webkit-slider-runnable-track {
                width: 100%;
                height: 5px;
                cursor: pointer;
                background: #6B7B98;
                border-radius: 1px;
                box-shadow: none;
                border: 0;
            }

            input[type=range]::-webkit-slider-thumb {
                z-index: 2;
                position: relative;
                width: 16px;
                height: 16px;
                border: 2px solid #FFFFFF;
                background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
                cursor: pointer;
                -webkit-appearance: none;
                margin-top: -8px;
            }

            input[type=range]:disabled::-webkit-slider-thumb {
                background: #6B7B98;
            }
        }

        &__text-wrapper {
            display: flex;
            justify-content: space-between;

            .range__line-label {
                margin-top: 12px;
                user-select: none;
            }
        }
    }

</style>
