var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "element",
      class: { "element--shown": _vm.shown },
      on: { transitionend: _vm.toggleOpen },
    },
    [
      _c(
        "div",
        { staticClass: "element-title", on: { click: _vm.toggleItem } },
        [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c("BaseIcon", {
            staticClass: "arrow-svg",
            attrs: { name: "arrow" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "element-content",
          class: { "element-content--opened": _vm.opened },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }