<template>
    <div @keyup.enter="onEnter" class="filters">
        <span v-if="isOpen" class="filters-arrow-svg--close" @click="setVisible">
            <svg width='9' height='20' viewBox='0 0 9 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M8 1L2 10L8 19' stroke='white' stroke-width='2'/>
            </svg>
        </span>


        <div @click="setVisible" class="filters__title">
            <span class="filters__title-text">FILTER</span>

            <BaseIcon
                v-if="!isOpen"
                class="filters__title-arrow-svg"
                name="arrow"
            />
        </div>

        <div
            :class="{
                'filters__wrapper--mobi': !isFullWidth,
                'filters__wrapper--opened': isOpen
            }"
            class="filters__wrapper"
        >
            <ToggleElement v-if="isLoggedIn" v-bind:shown.sync="filters.myNfts.shown" :title="'MY NFTS'">
                <Checkbox
                    name="isMyNfts"
                    label="SHOW"
                    v-model="filters.myNfts.value"
                    class="filters-checkbox"
                />
            </ToggleElement>

            <ToggleElement v-bind:shown.sync="filters.level.shown" :title="'LEVEL'">
                <div class="item item--row">
                    <input
                        :class="{'number-input--empty': !filters.level.value}"
                        class="number-input"
                        type="number"
                        v-model="filters.level.value"
                        @keypress="validateNumbers"
                        @input="filters.level.set()"
                    >
                    <span>enter a level from 1 to 100</span>
                </div>
            </ToggleElement>

            <ToggleElement v-bind:shown.sync="filters.tier.shown" :title="'TIER'">
                <div class="item">
                    <div
                        v-for="(item, index) of filters.tier.options"
                        :key="index"
                        @click="filters.tier.set(item.value)"
                        :class="[
                            `tier-block tier-block--${item.label}`,
                            {'tier-block--checked': filters.tier.value
                                                    && filters.tier.value.indexOf(String(item.value)) >= 0
                            }
                        ]"
                        class="tier-block tier-block--fighter"
                        v-popover.top="{ name: `tier-${item.label}` }"
                    />
                </div>
            </ToggleElement>

            <ToggleElement v-bind:shown.sync="filters.type.shown" :title="'FIGHTER'">
                <Select :options="types" :default="filters.type.value || 'All'" @input="filters.type.set"/>
            </ToggleElement>

            <ToggleElement v-bind:shown.sync="filters.losses.shown" :title="'LOSSES'">
                <div class="item item--row">
                    <input
                        :class="{'number-input--empty': !filters.losses.value}"
                        class="number-input"
                        type="number"
                        v-model="filters.losses.value"
                        @keypress="validateNumbers"
                        @input="filters.losses.set()"
                    >
                    <span>enter a maximum acceptable number</span>
                </div>
            </ToggleElement>

            <ToggleElement v-bind:shown.sync="filters.wins.shown" :title="'WINS'">
                <div class="item item--row">
                    <input
                        :class="{'number-input--empty': !filters.wins.value}"
                        class="number-input"
                        type="number"
                        v-model="filters.wins.value"
                        @keypress="validateNumbers"
                        @input="filters.wins.set()"
                    >
                    <span>enter a minimum desirable number</span>
                </div>
            </ToggleElement>

            <ToggleElement v-bind:shown.sync="filters.skills.shown" :title="'STAKED'">
                <Checkbox
                    name="active"
                    label="Active"
                    v-model="filters.skills.active"
                    class="filters-checkbox"
                    @input="filters.skills.set()"
                />
                <Checkbox
                    name="passive"
                    label="Passive"
                    class="filters-checkbox"
                    v-model="filters.skills.passive"
                    @input="filters.skills.set()"
                />
            </ToggleElement>

            <ToggleElement v-bind:shown.sync="filters.booster.shown" :title="'BOOSTER'">
                <Checkbox
                    name="active"
                    label="2X"
                    v-model="filters.booster.two"
                    class="filters-checkbox"
                    @input="filters.booster.set()"
                />
                <Checkbox
                    name="passive"
                    label="4X"
                    class="filters-checkbox"
                    v-model="filters.booster.four"
                    @input="filters.booster.set()"
                />
            </ToggleElement>

            <ToggleElement v-bind:shown.sync="filters.haracteristics.shown" :title="'SKILLS'">
                <div class="filters__haracteristic">
                    <div class="filters__haracteristic-title">choose a range from 1 to 100</div>

                    <div
                        v-for="item of filters.haracteristics.options"
                        :key="item.name"
                        class="item item--row"
                    >
                        <Checkbox
                            :name="item.name"
                            :label="item.name"
                            v-model="item.value"
                            class="filters-checkbox"
                        />

                        <Range :isEnabled="item.value" v-model="item.range" @input="filters.haracteristics.set()"/>
                    </div>
                </div>
            </ToggleElement>

            <ToggleElement v-bind:shown.sync="filters.experience_gte.shown" :title="'EXPERIENCE'">
                <div class="item item--row item--mb">
                    <input
                        :class="{'number-input--empty': !filters.experience_gte.value}"
                        class="number-input"
                        type="number"
                        v-model="filters.experience_gte.value"
                        @keypress="validateNumbers"
                        @input="filters.experience_gte.set()"
                    >
                    <span>enter a minimum experience</span>
                </div>

                <div class="item item--row item--mb">
                    <input
                        :class="{'number-input--empty': !filters.experience_lte.value}"
                        class="number-input"
                        type="number"
                        v-model="filters.experience_lte.value"
                        @keypress="validateNumbers"
                        @input="filters.experience_lte.set()"
                    >
                    <span>enter a maximum experience number</span>
                </div>
            </ToggleElement>

            <ToggleElement v-bind:shown.sync="filters.head_color.shown" :title="'HEAD COLOR'">
                <ColorPicker v-model="filters.head_color.value"/>
            </ToggleElement>
            <ToggleElement v-bind:shown.sync="filters.body_color.shown" :title="'BODY COLOR'">
                <ColorPicker v-model="filters.body_color.value"/>
            </ToggleElement>
            <ToggleElement v-bind:shown.sync="filters.legs_color.shown" :title="'LEG COLOR'">
                <ColorPicker v-model="filters.legs_color.value"/>
            </ToggleElement>
            <ToggleElement v-bind:shown.sync="filters.hands_color.shown" :title="'HAND COLOR'">
                <ColorPicker v-model="filters.hands_color.value"/>
            </ToggleElement>
            <ToggleElement v-bind:shown.sync="filters.weapon_color.shown" :title="'WEAPON COLOR'">
                <ColorPicker v-model="filters.weapon_color.value"/>
            </ToggleElement>
            <div class="filters-apply-btns">
                <btn
                    v-if="isFilterChanged"
                    type="icon-gradient"
                    text="Apply filters"
                    :text-size="13"
                    @click="applyFilter"
                />

                <btn
                    v-if="!isFiltersEmpty"
                    type="icon-gradient"
                    text="Clear filters"
                    :text-size="13"
                    @click="clearFilter"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ToggleElement from './ToggleElement';
import Select from '@/components/common/select';
import Checkbox from "@/components/common/checkbox";
import Range from '@/components/common/range';
import ColorPicker from '@/components/common/color-picker';
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Filters",
    components: {
        ToggleElement,
        Select,
        Checkbox,
        Range,
        ColorPicker,
    },
    computed: {
        ...mapGetters(['GET_CHARACTERS', 'GET_WINDOW_WIDTH', 'isLoggedIn']),
        ...mapGetters('filters', ['getFilters']),

        types() {
            return ['All', ...this.GET_CHARACTERS.map((character) => character.type)];
        },

        isFullWidth() {
            return this.GET_WINDOW_WIDTH > 1115;
        },
        formattedFilters() {
            const filters = {};

            Object.keys(this.filters).forEach((key) => {
                const value = this.filters[key].value;

                if (Array.isArray(value)) {
                    filters[key] = value.length ? value : null;
                } else {
                    filters[key] = value;
                }
            })

            return filters;
        },
        isFilterChanged() {
            return JSON.stringify(this.formattedFilters) !== JSON.stringify(this.prevFiltersValue);
        },
        isFiltersEmpty() {
            return Object.entries(this.getFilters).every(([key, value]) => {
                if (Object.prototype.hasOwnProperty.call(this.filters[key], 'defaultValue')) {
                    return this.filters[key]?.defaultValue === value;
                }

                return !value || value === 'All';
            });
        },
    },
    watch: {
        isOpen(val) {
            if (val) {
                window.scrollTo(0, 0);
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
    },
    async mounted() {
        if (!this.GET_CHARACTERS.length) {
            await this.REQUEST_CHARACTERS();
        }

        await this.$nextTick();

        // eslint-disable-next-line
        const {page, ...filters} = this.$route.query;

        if (Object.keys(filters).length) {
            this.parseUrlFilters(filters);
        } else {
            this.parseStoreFilters();
        }

        this.applyFilter();
        window.addEventListener('keypress', this.onEnter);
    },
    beforeDestroy() {
        window.removeEventListener('keypress', this.onEnter);
    },
    data() {
        return {
            isOpen: false,
            prevFiltersValue: null,
            applyBtnPosition: 'unset',
            filters: {
                myNfts: {
                    shown: false,
                    value: true,
                    defaultValue: true,
                    set: (value = false) => {
                        this.filters.myNfts.value = Boolean(value);
                    },
                    setDefaults: () => {
                        this.filters.myNfts.value = this.filters.myNfts.defaultValue;
                    },
                },
                level: {
                    shown: false,
                    value: null,
                    validate: (value) => {
                        const maxValue = 100;
                        const minValue = 0;
                        const valueNum = Number(value);

                        if (value.length === 0) {
                            return null;
                        }

                        if (valueNum > maxValue) {
                            return maxValue;
                        }

                        if (valueNum < minValue) {
                            return minValue;
                        }

                        return valueNum;
                    },
                    set: (value = null) => {
                        const item = this.filters.level;

                        item.value = item.validate(value || item.value);
                    }
                },
                tier: {
                    shown: false,
                    value: null,
					set: (value) => {
                        if (value === null) {
                            return;
                        }

						value = String(value);

						const tier = this.filters.tier;
						const currentValues = tier.value ? tier.value.split(',') : '';
						let newValue;

						if (currentValues && currentValues.some((el) => String(el) === value)) {
							newValue = currentValues.filter((el) => String(el) !== value);
						} else {
							newValue = [...currentValues, value];
						}

                        newValue = newValue.filter((el) => el);
						tier.value = newValue.toString();
					},
                    options: [
                        {
                            label: 'common',
                            value: 1,
                        },
                        {
                            label: 'fighter',
                            value: 2,
                        },
                        {
                            label: 'ultimate',
                            value: 3,
                        },
                        {
                            label: 'epic',
                            value: 4,
                        },
                        {
                            label: 'legendary',
                            value: 5,
                        },
                    ]
                },
                type: {
                    shown: false,
                    value: null,
                    set: (value) => {
                        this.filters.type.value = value;
                    }
                },
                losses: {
                    shown: false,
                    value: null,
                    validate: (value) => {
                        const minValue = 0;
                        const valueNum = Number(value);

                        if (value.length === 0) {
                            return null;
                        }

                        if (valueNum < minValue) {
                            return minValue;
                        }

                        return valueNum;
                    },
                    set: (value = null) => {
                        const item = this.filters.losses;

                        item.value = item.validate(value || item.value);
                    }
                },
                wins:  {
                    shown: false,
                    value: null,
                    validate: (value) => {
                        const minValue = 0;
                        const valueNum = Number(value);

                        if (value.length === 0) {
                            return null;
                        }

                        if (valueNum < minValue) {
                            return minValue;
                        }

                        return valueNum;
                    },
                    set: (value = null) => {
                        const item = this.filters.wins;

                        item.value = item.validate(value || item.value);
                    }
                },
                skills: {
                    shown: false,
                    active: false,
                    passive: false,
                    value: null,
                    set: (value = null) => {
                        const skills = this.filters.skills;

                        if (value) {
                            value.split(',').forEach((el) => skills[el] = true);
                        }

                        const newValue = [];

                        if (skills.active) {
                            newValue.push('active');
                        }
                        if (skills.passive) {
                            newValue.push('passive');
                        }

                        skills.value = newValue.toString();
                    },
                    setDefaults: () => {
                        const skills = this.filters.skills;

                        skills.active = false;
                        skills.passive = false;
                        skills.value = null;
                    },
                },
                booster: {
                    shown: false,
                    two: false,
                    four: false,
                    value: null,
                    set: (value = null) => {
                        const booster = this.filters.booster;

                        if (value) {
                            switch (value) {
                                case 'x2_booster': {
                                    booster.two = true;
                                    break;
                                }
                                case 'x4_booster': {
                                    booster.four = true;
                                    break;
                                }
                                case 'all': {
                                    booster.two = true;
                                    booster.four = true;
                                    break;
                                }
                            }
                        }

                        if (booster.two === booster.four) {
                            booster.value = booster.two ? 'all' : false;

                            return;
                        }

                        booster.value = booster.two ? 'x2_booster' : 'x4_booster' ;
                    },
                    setDefaults: () => {
                        const booster = this.filters.booster;

                        booster.two = false;
                        booster.four = false;
                        booster.value = null;
                    },
                },
                haracteristics: {
                    shown: false,
                    options: [
						{
							name: 'ATK',
							value: false,
							range: [1, 100],
						},
						{
							name: 'DEF',
							value: false,
							range: [1, 100],
						},
						{
							name: 'SPD',
							value: false,
							range: [1, 100],
						},
                        {
                            name: 'STM',
                            value: false,
                            range: [1, 100],
                        },
                    ],
                    value: null,
                    set: (value = null) => {
                        const haracteristics = this.filters.haracteristics;

                        if (value) {
                            const newValues = [];

                            value.forEach((element) => {
                                // парсим каждый параметр
                                Object.entries(element).forEach(([key, value]) => {
                                    const keyParsed = key.split('_');
                                    const haracteristic = haracteristics.options.find((el) => String(el.name).toLowerCase() === keyParsed[0]);

                                    if (!haracteristic) {
                                        return;
                                    }

                                    const rangeIndex = keyParsed[1] === 'gte' ? 0 : 1;

                                    if (!haracteristic.newRange) {
                                        haracteristic.newRange = [];
                                    }
                                    if (value > 100) {
                                        value = 100;
                                    }
                                    if (value < 1) {
                                        value = 1;
                                    }

                                    haracteristic.value = true;
                                    haracteristic.newRange[rangeIndex] = value;

                                    if (!newValues.find((el) => el.name === haracteristic.name)) {
                                        newValues.push(haracteristic);
                                    }
                                })
                            });

                            // изменяем значения в рапарсенных значениях (для избежания мутации)
                            newValues.forEach((el) => el.range = el.newRange);
                        }

                        const optionsFiltered = haracteristics.options.filter((item) => item.value);
                        const newValue = [];

                        optionsFiltered.forEach((item) => {
                            const keyLow = `${String(item.name).toLowerCase()}_gte`;
                            const keyHigh = `${String(item.name).toLowerCase()}_lte`;


                            newValue.push({
                                [keyLow]: item.range[0],
                                [keyHigh]: item.range[1],
                            })
                        });

                        haracteristics.value = newValue;
                    },
                    setDefaults: () => {
                        const haracteristics = this.filters.haracteristics;

                        haracteristics.options.forEach((el) => el.value = false);
                        haracteristics.value = null;
                    },
                },
                experience_gte: {
                    shown: false,
                    value: null,
                    validate: (value) => {
                        const minValue = 0;
                        const valueNum = Number(value);

                        if (value.length === 0) {
                            return null;
                        }

                        if (valueNum < minValue) {
                            return minValue;
                        }

                        return valueNum;
                    },
                    set: (value) => {
                        const item = this.filters.experience_gte;

                        item.value = item.validate(value || item.value);
                    }
                },
                experience_lte: {
                    shown: false,
                    value: null,
                    validate: (value) => {
                        const minValue = 0;
                        const valueNum = Number(value);

                        if (value.length === 0) {
                            return null;
                        }

                        if (valueNum < minValue) {
                            return minValue;
                        }

                        return valueNum;
                    },
                    set: (value) => {
                        const item = this.filters.experience_lte;

                        item.value = item.validate(value || item.value);
                    }
                },
                head_color: {
                    shown: false,
                    value: null,
                    setDefaults: () => {
                        this.filters.head_color.value = '';
                    }
                },
                body_color: {
                    shown: false,
                    value: null,
                    setDefaults: () => {
                        this.filters.body_color.value = '';
                    }
                },
                legs_color: {
                    shown: false,
                    value: null,
                    setDefaults: () => {
                        this.filters.legs_color.value = '';
                    }
                },
                hands_color: {
                    shown: false,
                    value: null,
                    setDefaults: () => {
                        this.filters.hands_color.value = '';
                    }
                },
                weapon_color: {
                    shown: false,
                    value: null,
                    setDefaults: () => {
                        this.filters.weapon_color.value = '';
                    }
                },
            },
        }
    },
    methods: {
        ...mapActions(['REQUEST_CHARACTERS']),
        ...mapActions('filters', ['setFilters']),

        parseUrlFilters(filters) {
            // функция парсинга фильтров (вызывается только один раз для установки фильтров из url)
            const haracteristics = [];

            // заполняем все фильтры кроме зарактеристик
            Object.entries(filters).forEach(([key, value]) => {
                let filter = this.filters[key];

                if (!filter) {
                    // не нашли фильтр, если характеристика то заполняем
                    if (key.indexOf('_gte') > 0 || key.indexOf('_lte') > 0) {
                        haracteristics.push({[key]: value});
                    }

                    return;
                }

                if (key === 'myNfts' && value) {
                    return;
                }

                this.toggleItem(filter);

                if (filter.set) {
                    filter.set(value);
                } else if (filter.value !== undefined) {
                    filter.value = value;
                }
            })

            // проверяем характеристики
            if (haracteristics.length) {
                const filter = this.filters.haracteristics;

                filter.set(haracteristics);
                this.toggleItem(filter);
            }
        },
        parseStoreFilters() {
            Object.entries(this.getFilters).forEach(([key, value]) => {
                if (!value || value === 'All') {
                    return;
                } else if (key === 'myNfts') {
                    return;
                }

                let filter = this.filters[key];

                this.toggleItem(filter);

                if (filter.set) {
                    filter.set(value);
                } else if (filter.value !== undefined) {
                    filter.value = value;
                }
            })
        },
        onEnter(event) {
            if (event.keyCode !== 13 || !this.isFilterChanged) {
                return;
            }

            this.applyFilter();
        },
        updateFilters() {
            this.prevFiltersValue = {...this.formattedFilters};
        },
        setVisible() {
            if (this.isFullWidth) {
                return;
            }

            this.isOpen = !this.isOpen;
        },
        toggleItem(item) {
            item.shown = !item.shown;
        },
        validateNumbers(event) {
            if (!/^[0-9]*$/g.test(event.key)) {
                event.preventDefault();
            }
        },
        applyFilter() {
            this.updateFilters();
            this.setFilters(this.formattedFilters);
            this.$emit('filterApply', this.formattedFilters);
        },
        clearFilter() {
            Object.keys(this.filters).forEach((el) => {
                const filter = this.filters[el];

                if (filter.setDefaults) {
                    filter.setDefaults();
                } else {
                    filter.value = null;
                }
            });
            this.applyFilter();
        },
    }
}
</script>

<style lang="scss">
    .filters {
        width: 275px;

        &__title {
            font: 800 24px Raleway;
            position: relative;
            height: 57px;
            padding: 15px 0;

            &-text {
                color: white;
                position: absolute;
            }

            &-arrow-svg {
                display: none;
                position: absolute;
                right: 15px;
                top: calc(50% - 3px);
            }

            &:before {
                position: absolute;
                content: "";
                background: linear-gradient(270deg, #15257C 37.48%, rgba(21, 37, 124, 0) 99.15%);
                height: 100%;
                right: 0;
                left: -25%;
                top: 0;
            }

            @media screen and (max-width: $bp_mb) {
                font-size: 16px;
                height: 32px;
                padding: 8px 0;
                margin-top: 20px;
                transform: skew(-4deg);

                &-arrow-svg {
                    display: inline-block;
                }
            }
        }

        &__wrapper {
            position: relative;
            color: #BFBFBF;
            font-weight: 500;

            @media screen and (max-width: 1115px) {
                display: none;
            }

            &--opened {
                display: block;
            }

            &--mobi {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 199px;
                padding: 0 1.5em;
                z-index: 2;
                background: linear-gradient(180deg, #1f213a00 0%, #000000 100%), #1F213A;
                overflow-y: auto;

                @media screen and (max-width: $bp_mb) {
                    top: 180px;
                    padding-bottom: 6em;
                    box-shadow: inset 0px 0px 5px 0px #02020466;
                }
            }
        }

        .item {
            display: flex;
            gap: 8px;

            &--row {
                gap: 15px;
                align-items: center;
            }

            &--column {
                flex-direction: column;
            }

            &--mb {
                margin-bottom: 1em;
            }
        }

        .number-input {
            width: 64px;
            height: 40px;
            color: #FFCC13;
            padding: 8px;
            background: transparent;
            border: 1px solid #BFBFBF;
            text-align: center;

            &--empty {
                color: #BFBFBF;
            }
        }

        .tier-block {
            width: 40px;
            height: 40px;
            cursor: pointer;

            &--fighter{
                background: #4488FF;
            }
            &--ultimate{
                background: #33DE98;
            }
            &--epic{
                background: #DB76FF;
            }
            &--legendary{
                background: #FFBB0B;
            }
            &--common{
                background: #C8C8C8;
            }

            &--checked:after {
                content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none"><path d="M2 6L7.25 11L16 1" stroke="black" stroke-width="3"/></svg>');
                display: block;
                width: 22px;
                height: 10px;
                margin: 10px 5px 0 10px;
            }
        }

        &-checkbox {
            font: 400 19px "Tektur";
            color: #BFBFBF;
            margin-bottom: 5px;

            .checkbox-checkmark {
                border: 2px solid #BFBFBF;

                &:after {
                    border-color: black;
                    left: 5px;
                    top: 1px;
                    width: 7px;
                    height: 11px;
                    border-width: 0 4px 4px 0;
                }
            }

            .checkbox-input {
                &:checked ~ .checkbox-checkmark {
                    background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
                }
            }

            &:hover {
                .checkbox-label {
                    color: #FFCC13;
                }

            }
        }

        &-checkbox--staking {
            gap: 0;
            margin-bottom: 0;

            .checkbox-checkmark {
                border: 2px solid #fff;

                &::after {
                    left: 6px;
                    top: 3px;
                }
            }

            .checkbox-input {
                &:checked ~ .checkbox-checkmark {
                    border: 0;
                }
            }
        }

        &-booster {
            font: 400 20px "Tektur";
            cursor: pointer;

            &--active,
            &:hover {
                color: #FFCC13;
            }
        }

        &__haracteristic {
            &-title {
                font: 500 14px "Tektur";
                margin-bottom: 10px;
            }

            .item {
                margin-bottom: 15px;
            }
        }

        &-apply-btns {
            display: flex;
            justify-content: center;
            text-align: center;
            padding-top: 20px;
            gap: 10px;
            z-index: 2;
            bottom: 0px;

            .button--icon-gradient {
                padding: 10px 15px;
            }

            @media screen and (max-width: $bp_mb) {
                position: fixed;
                bottom: 1.5em;
                left: 0;
                width: 100%;
            }
        }

        &-arrow-svg--close {
            display: none;
            position: absolute;
            margin-top: -35px;
            padding-right: 50%;

            @media screen and (max-width: $bp_mb) {
                display: inline-block;
            }
        }
    }
</style>
