<template>
    <div
        :class="{'element--shown': shown}"
        class="element"
        @transitionend="toggleOpen"
    >
        <div
            @click="toggleItem"
            class="element-title"
        >
            <span>{{title}}</span>

            <BaseIcon
                class="arrow-svg"
                name="arrow"
            />
        </div>

        <div
            :class="{'element-content--opened': opened}"
            class="element-content"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "ToggleElement",
    props: {
        shown: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            opened: false,
        }
    },
    methods: {
        toggleItem() {
            this.opened = false;
            this.$emit('update:shown', !this.shown);
        },
        toggleOpen() {
            this.opened = true;
        }
    }
}
</script>

<style lang="scss" scoped>
    .element {
        position: relative;
        padding: 15px;

        &:after {
            position: absolute;
            content: "";
            background: linear-gradient(270deg, #15257C 37.48%, rgba(21, 37, 124, 0) 99.15%);
            height: 2px;
            right: 0;
            left: -25%;
            bottom: 0;
        }

        &--shown {
            .arrow-svg {
                transform: rotate(180deg);
            }
            .element-content {
                opacity: 1;
                padding-top: 15px;
                max-height: 250px;
            }
        }

        &-title {
            font: 500 18px "Tektur";
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;


        }

        &-content {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: 0.5s;

            &--opened {
                overflow: inherit;
            }
        }
    }
</style>
