// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".colorpicker-wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.colorpicker-wrapper .color {\n  position: relative;\n  display: flex;\n  cursor: pointer;\n  font: 500 12px Tektur;\n  align-items: center;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  user-select: none;\n}\n.colorpicker-wrapper .color--default {\n  color: white;\n}\n.colorpicker-wrapper .color-btn {\n  width: 40px;\n  height: 40px;\n  border: 1px solid white;\n  line-height: 37px;\n  font-size: 18px;\n  text-align: center;\n}\n.colorpicker-wrapper .color-filled {\n  width: 40px;\n  height: 40px;\n}\n.colorpicker-wrapper .color-input {\n  width: 0;\n  padding: 0;\n  border: none;\n  visibility: hidden;\n}\n.colorpicker-wrapper .color-label {\n  margin-left: 1em;\n  text-transform: uppercase;\n}\n.colorpicker-wrapper .color-icon {\n  position: absolute;\n  left: 8px;\n  top: 8px;\n  display: none;\n}\n.colorpicker-wrapper .color-clear {\n  cursor: pointer;\n  font-size: 12px;\n}\n.colorpicker-wrapper .color:hover .color-label {\n  color: gray;\n}\n.colorpicker-wrapper .color:hover .color-icon {\n  display: block;\n}\n.colorpicker-wrapper .color:hover .color-btn {\n  color: black;\n  background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);\n  border-width: 0;\n}\n.colorpicker-wrapper .verte__guide {\n  width: initial;\n  height: initial;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
