var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "colorpicker-wrapper",
      on: {
        "~click": function ($event) {
          return _vm.enableColorPicker.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "verte",
        {
          attrs: {
            picker: "square",
            model: "hex",
            value: _vm.color,
            enableAlpha: false,
            menuPosition: "left",
          },
          on: { input: _vm.setColor },
        },
        [
          _c(
            "label",
            { staticClass: "color", class: { "color--default": !_vm.color } },
            [
              _vm.value
                ? _c("BaseIcon", {
                    staticClass: "color-icon",
                    attrs: { name: "refresh" },
                  })
                : _vm._e(),
              !_vm.value
                ? _c("span", { staticClass: "color-btn" }, [_vm._v("+")])
                : _c("span", {
                    staticClass: "color-filled",
                    style: { backgroundColor: `#${_vm.value}` },
                  }),
              _c("span", { staticClass: "color-label" }, [
                _vm._v(_vm._s(_vm.color || _vm.label)),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm.value
        ? _c(
            "span",
            {
              staticClass: "color-clear",
              on: {
                click: function ($event) {
                  return _vm.setColor("")
                },
              },
            },
            [_vm._v("delete color")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }