<template>
    <Content>
        <template slot="content">
            <div class="shop-wrapper">
                <Filters @filterApply="filterApply" v-if="isFullWidth"/>

                <div class="shop-wrapper__main">
                    <div v-if="!isFullWidth" class="shop-settings">
                        <Filters @filterApply="filterApply"/>
                    </div>

                    <market-place-header>
                        <template v-if="!GET_IS_MOBILE" slot="actions">
                            <div class="btns-boosters">
                                <BtnTwice
                                    v-for="booster in boosters"
                                    :key="booster.id"
                                    :leftText="`${booster.name} BOOSTER`"
                                    :rightText="`BUY ${toLocaleFormat(booster.price)} MF`"
                                    :hoverText="`BUY ${toLocaleFormat(booster.usd_price)} $`"
                                    @click="buyBoosterHandler(booster.id)"
                                />
                            </div>
                        </template>
                    </market-place-header>

                    <div class="pagination-content">
                        <div class="sort-select">
                            <span class="sort-select-icon">
                                <svg width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.697266 0.545456H22.5154L21.3033 2.9697H0.697266V0.545456Z" fill="#262626"/>
                                    <path d="M0.697266 5.39394H20.0912L18.8791 7.81818H0.697266V5.39394Z" fill="#262626"/>
                                    <path d="M0.697266 10.2424H17.667L16.4548 12.6667H0.697266V10.2424Z" fill="#262626"/>
                                </svg>
                            </span>

                            <Select
                                color="white"
                                :options="getSortOptions"
                                :default="getSort"
                                @input="setSort"
                            />
                        </div>

                        <Pagination
                            v-show="pageSize < SHOP_CHARACTERS_COUNT"
                            :currentPage="Number(currentPage)"
                            :pageCount="pagesCount"
                            :navigationEnabled="true"
                            @clickHandler="pageHandler"
                        />
                    </div>

                    <Loader v-if="isListLoading"/>

                    <div v-else class="nft-cards">
                        <transition name="slide-fade" mode="out-in">
                            <div v-if="isMounted" class="nft-cards__link" @click="goToUrl('/marketplace/mint')">
                                <div class="nft-cards__link-text">
                                    <span class="nft-cards__link-plus">+</span>
                                    <span class="nft-cards__link-label">MINT FIGHTER</span>
                                </div>
                            </div>
                        </transition>

                        <shop-nft-card
                            v-for="(character, i) in shopCharactersFiltered"
                            :key="i"
                            :transitionDuration="i * 100"
                            :name="character.nft.name"
                            :seller="character.seller"
                            v-bind="character.nft.mf_attributes"
                            class="nft-cards__item"
                            @click="onCardClick(character)"
                            @btn-click="onBtnClick(character)"
                        />
                    </div>
                </div>
            </div>

            <approve-popup
                ref="approvePopup"
                action-btn-text="BUY"
                successMsg="You have approved a sufficient number of MF tokens. Now you can buy the character."
                @action-btn-click="buyCharacter"
            />
            <transaction-success-popup ref="transactionSuccessPopup"/>
            <preloader translucent black v-if="isProcessing" />
        </template>
    </Content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Content from '@/components/common/content.vue';
import ShopNftCard from '@/components/common/shop-nft-card.vue';
import MarketPlaceHeader from '@/components/market-place/header.vue';
import Loader from '@/components/common/loader.vue';
import BtnTwice from '@/components/common/btn-twice';
import Filters from '@/components/filters/filters';
import Pagination from '@/components/common/pagination.vue'
import MarketplaceMixin from '@/mixins/marketplaceMixin';
import {calculatePagesCount, isStringsEquals} from "@/utils/helpers";
import CharactersConfig from "@/constants/CharactersConfig";
import Select from '@/components/common/select';

export default {
    name: 'ShopList',
    mixins: [MarketplaceMixin],
    components: {
        BtnTwice,
        Content,
        ShopNftCard,
        MarketPlaceHeader,
        Loader,
        Filters,
        Pagination,
        Select,
    },
    computed: {
        ...mapGetters([
            'GET_IS_MOBILE',
            'GET_WINDOW_WIDTH',
            'GET_SHOP_CHARACTERS',
            'SHOP_CHARACTERS_COUNT',
            'account',
        ]),
        ...mapGetters('auth', ['user']),
        ...mapGetters('filters', ['getSort', 'getSortOptions', 'getSortOrdering']),

        isFullWidth() {
            return this.GET_WINDOW_WIDTH > 1115;
        },

        pagesCount() {
            return calculatePagesCount(this.pageSize, this.SHOP_CHARACTERS_COUNT);
        },
        props() {
            return {
                ...this.filter,
                page_size: this.pageSize,
                ordering: this.getSortOrdering,
                page: this.currentPage
            };
        },
        shopCharactersFiltered() {
            if (!this.filter.myNfts) {
                return this.GET_SHOP_CHARACTERS.filter((nft) => nft.seller !== this.account);
            }

            return this.GET_SHOP_CHARACTERS;
        },
    },
    watch: {
        props() {
            this.getShopCharacters();
        },
    },
    data() {
        return {
            isListLoading: true,
            isMounted: false,
            transitionDuration: 150,
            pageSize: CharactersConfig.shopPageSize,
            currentPage: this.$route.query.page || 1,
            filter: {},
        }
    },
    methods: {
        ...mapActions([
            'REQUEST_SHOP_CHARACTERS',
            'getSmartContract',
            'getMFToken',
        ]),
        ...mapActions('filters', ['setSort']),

        filterApply(filters) {
            const validatedFilters = {};

            Object.keys(filters).forEach((el) => {
                if (!filters[el] || filters[el] === 'All') {
                    return;
                }

                if (typeof filters[el] === 'object') {
                    // TODO: stupid way, do not have a time. rework later
                    Object.values(filters[el]).forEach((value) => {
                        if (typeof filters[el] === 'object') {
                            Object.entries(value).forEach(([key, value]) => {
                                validatedFilters[key] = value;
                            });
                        }
                    });
                } else {
                    validatedFilters[el] = filters[el];
                }
            });

            this.filter = validatedFilters;
            this.currentPage = 1;
        },
        async getShopCharacters() {
            this.isListLoading = true;
            this.setUrlParams();

            try {
                await this.REQUEST_SHOP_CHARACTERS(this.props);
            } catch {
                if (this.currentPage !== 1) {
                    this.pageHandler(1);
                }
            }

            this.isListLoading = false;
            this.renderLink();
        },

        onCardClick(character) {
            this.$router.push({
                name: 'shopSingle',
                params: {
                    person: character.id,
                }
            });
        },

        onBtnClick(character) {
            if (isStringsEquals(character.seller, this.account)) {
                this.onCancelListingClick(character.nft.mf_attributes.token_id);
            } else {
                this.onBuyNftClick(character);
            }
        },

        renderLink() {
            setTimeout(() => {
                this.isMounted = true;
            }, this.transitionDuration)
        },

        pageHandler(page) {
            this.currentPage = page
            this.setUrlParams();
        },
        setUrlParams() {
            const queryParams = new URLSearchParams(this.props).toString();

            history.pushState(null, null, "?"+queryParams.toString());
        },
    },
}
</script>

<style lang="scss" scoped>
    .pagination-content {
        margin: 2em 1.5em;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sort-select {
            display: flex;

            &-icon {
                background: #BFBFBF;
                padding: 8px;
            }

            .custom-select {
                height: 40px;
                line-height: 40px;

                .selected {
                    color: white;
                }
            }
        }
    }
    .shop-wrapper {
        display: flex;
        gap: 25px;

        &__main {
            width: 100%;
        }
    }
    .shop-settings {
        padding: 0em 1.5em 1.5em 1.5em;
    }
    .nft-cards__link {
        transform: skewX(-4deg);
        display: flex;
        width: 243px;
        background: linear-gradient(177deg, #1F213A 4.26%, #000000 206.41%);
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        user-select: none;
        cursor: pointer;
        height: 424px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            width: 10px;
            background: linear-gradient(to right, #ffffff21 -15%, transparent 50%);
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            height: 10px;
            background: linear-gradient(to bottom, #ffffff21 -15%, transparent 50%);
        }

        &-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            font: 500 16px "Tektur";
            color: white;
        }

        &-plus {
            color: black;
            width: 50px;
            line-height: 50px;
            font-size: 24px;
            text-align: center;
            background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
        }

        @media screen and (max-width: 1115px) {
            height: 215px;
        }
        @media screen and (max-width: 504px) {
            height: 94px;

            &-text {
                flex-direction: inherit;
            }
            &-plus {
                width: 32px;
                line-height: 32px;
            }
        }
    }

    .btns-boosters {
        margin-left: 1.5em;
        display: flex;
        gap: 2em;

        &-item {
            font: 500 14px "Tektur";
            display: flex;
            gap: 0.5em;
            color: #BFBFBF;
            align-items: center;
        }

        @media screen and (max-width: $bp_mb) {
            margin-top: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &-item {
                flex-direction: column;
            }
        }
    }
</style>
