<template>
    <div
        class="colorpicker-wrapper"
        @click.once="enableColorPicker"
    >
        <verte
            picker="square"
            model="hex"
            :value="color"
            :enableAlpha="false"
            menuPosition="left"
            @input="setColor"
        >
            <label
                :class="{'color--default': !color}"
                class="color"
            >
                <BaseIcon v-if="value" :name="'refresh'" class="color-icon"/>

                <span v-if="!value" class="color-btn">+</span>
                <span v-else class="color-filled" :style="{backgroundColor: `#${value}`}"></span>

                <span class="color-label">{{color || label}}</span>
            </label>
        </verte>

        <span
            v-if="value"
            class="color-clear"
            @click="setColor('')"
        >delete color</span>
    </div>
</template>

<script>
export default {
    name: "ColorPicker",
    props: {
        label: { type: String, default: 'PICK COLOR' },
        value: { type: String, default: null },
    },
    computed: {
        color: {
            get: function () {
                return this.value ? `#${this.value}` : '';
            },
            set: function (val) {
                this.$emit('input', val);
            }
        },
    },
    data() {
        return {
            isEnabled: false,
        }
    },
    methods: {
        setColor(value) {
            if (!this.isEnabled) {
                return;
            }

            this.color = value ? value.replace(/#/g, '')  : '';
        },
        enableColorPicker() {
            this.isEnabled = true;
        }
    }
}
</script>

<style lang="scss">
.colorpicker-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .color {
        position: relative;
        display: flex;
        cursor: pointer;
        font: 500 12px Tektur;
        align-items: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &--default {
            color: white;
        }

        &-btn {
            width: 40px;
            height: 40px;
            border: 1px solid white;
            line-height: 37px;
            font-size: 18px;
            text-align: center;
        }
        &-filled {
            width: 40px;
            height: 40px;
        }
        &-input {
            width: 0;
            padding: 0;
            border: none;
            visibility: hidden;
        }
        &-label {
            margin-left: 1em;
            text-transform: uppercase;
        }
        &-icon {
            position: absolute;
            left: 8px;
            top: 8px;
            display: none;
        }
        &-clear {
            cursor: pointer;
            font-size: 12px;
        }
        &:hover {
            .color-label {
                color: gray;
            }

            .color-icon {
                display: block;
            }

            .color-btn {
                color: black;
                background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
                border-width: 0;
            }
        }
    }

    .verte__guide {
        width: initial;
        height: initial;
    }
}
</style>
