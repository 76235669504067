var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "range" }, [
    _c(
      "div",
      {
        ref: "line",
        staticClass: "range__line",
        class: { "range__line--enabled": _vm.isEnabled },
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.sliderMin,
              expression: "sliderMin",
            },
          ],
          ref: "handle0",
          attrs: {
            type: "range",
            min: _vm.min,
            max: _vm.max,
            disabled: !_vm.isEnabled,
          },
          domProps: { value: _vm.sliderMin },
          on: {
            __r: function ($event) {
              _vm.sliderMin = $event.target.value
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.sliderMax,
              expression: "sliderMax",
            },
          ],
          ref: "handle1",
          attrs: {
            type: "range",
            min: _vm.min,
            max: _vm.max,
            disabled: !_vm.isEnabled,
          },
          domProps: { value: _vm.sliderMax },
          on: {
            __r: function ($event) {
              _vm.sliderMax = $event.target.value
            },
          },
        }),
        _vm.isEnabled
          ? _c("i", {
              staticClass: "range__line-bg",
              style: {
                left: `${_vm.sliderMin}%`,
                right: `${100 - _vm.sliderMax}%`,
              },
            })
          : _vm._e(),
        _c("div", { staticClass: "range__text-wrapper" }, [
          _c("span", { staticClass: "range__line-label" }, [
            _vm._v(_vm._s(_vm.value[0])),
          ]),
          _c("span", { staticClass: "range__line-label" }, [
            _vm._v(_vm._s(_vm.value[1])),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }