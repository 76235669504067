var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "filters",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.onEnter.apply(null, arguments)
        },
      },
    },
    [
      _vm.isOpen
        ? _c(
            "span",
            {
              staticClass: "filters-arrow-svg--close",
              on: { click: _vm.setVisible },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "9",
                    height: "20",
                    viewBox: "0 0 9 20",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M8 1L2 10L8 19",
                      stroke: "white",
                      "stroke-width": "2",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "filters__title", on: { click: _vm.setVisible } },
        [
          _c("span", { staticClass: "filters__title-text" }, [
            _vm._v("FILTER"),
          ]),
          !_vm.isOpen
            ? _c("BaseIcon", {
                staticClass: "filters__title-arrow-svg",
                attrs: { name: "arrow" },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "filters__wrapper",
          class: {
            "filters__wrapper--mobi": !_vm.isFullWidth,
            "filters__wrapper--opened": _vm.isOpen,
          },
        },
        [
          _vm.isLoggedIn
            ? _c(
                "ToggleElement",
                {
                  attrs: { shown: _vm.filters.myNfts.shown, title: "MY NFTS" },
                  on: {
                    "update:shown": function ($event) {
                      return _vm.$set(_vm.filters.myNfts, "shown", $event)
                    },
                  },
                },
                [
                  _c("Checkbox", {
                    staticClass: "filters-checkbox",
                    attrs: { name: "isMyNfts", label: "SHOW" },
                    model: {
                      value: _vm.filters.myNfts.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters.myNfts, "value", $$v)
                      },
                      expression: "filters.myNfts.value",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "ToggleElement",
            {
              attrs: { shown: _vm.filters.level.shown, title: "LEVEL" },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.level, "shown", $event)
                },
              },
            },
            [
              _c("div", { staticClass: "item item--row" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.level.value,
                      expression: "filters.level.value",
                    },
                  ],
                  staticClass: "number-input",
                  class: { "number-input--empty": !_vm.filters.level.value },
                  attrs: { type: "number" },
                  domProps: { value: _vm.filters.level.value },
                  on: {
                    keypress: _vm.validateNumbers,
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filters.level,
                          "value",
                          $event.target.value
                        )
                      },
                      function ($event) {
                        return _vm.filters.level.set()
                      },
                    ],
                  },
                }),
                _c("span", [_vm._v("enter a level from 1 to 100")]),
              ]),
            ]
          ),
          _c(
            "ToggleElement",
            {
              attrs: { shown: _vm.filters.tier.shown, title: "TIER" },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.tier, "shown", $event)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "item" },
                _vm._l(_vm.filters.tier.options, function (item, index) {
                  return _c("div", {
                    directives: [
                      {
                        name: "popover",
                        rawName: "v-popover.top",
                        value: { name: `tier-${item.label}` },
                        expression: "{ name: `tier-${item.label}` }",
                        modifiers: { top: true },
                      },
                    ],
                    key: index,
                    staticClass: "tier-block tier-block--fighter",
                    class: [
                      `tier-block tier-block--${item.label}`,
                      {
                        "tier-block--checked":
                          _vm.filters.tier.value &&
                          _vm.filters.tier.value.indexOf(String(item.value)) >=
                            0,
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.filters.tier.set(item.value)
                      },
                    },
                  })
                }),
                0
              ),
            ]
          ),
          _c(
            "ToggleElement",
            {
              attrs: { shown: _vm.filters.type.shown, title: "FIGHTER" },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.type, "shown", $event)
                },
              },
            },
            [
              _c("Select", {
                attrs: {
                  options: _vm.types,
                  default: _vm.filters.type.value || "All",
                },
                on: { input: _vm.filters.type.set },
              }),
            ],
            1
          ),
          _c(
            "ToggleElement",
            {
              attrs: { shown: _vm.filters.losses.shown, title: "LOSSES" },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.losses, "shown", $event)
                },
              },
            },
            [
              _c("div", { staticClass: "item item--row" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.losses.value,
                      expression: "filters.losses.value",
                    },
                  ],
                  staticClass: "number-input",
                  class: { "number-input--empty": !_vm.filters.losses.value },
                  attrs: { type: "number" },
                  domProps: { value: _vm.filters.losses.value },
                  on: {
                    keypress: _vm.validateNumbers,
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filters.losses,
                          "value",
                          $event.target.value
                        )
                      },
                      function ($event) {
                        return _vm.filters.losses.set()
                      },
                    ],
                  },
                }),
                _c("span", [_vm._v("enter a maximum acceptable number")]),
              ]),
            ]
          ),
          _c(
            "ToggleElement",
            {
              attrs: { shown: _vm.filters.wins.shown, title: "WINS" },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.wins, "shown", $event)
                },
              },
            },
            [
              _c("div", { staticClass: "item item--row" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.wins.value,
                      expression: "filters.wins.value",
                    },
                  ],
                  staticClass: "number-input",
                  class: { "number-input--empty": !_vm.filters.wins.value },
                  attrs: { type: "number" },
                  domProps: { value: _vm.filters.wins.value },
                  on: {
                    keypress: _vm.validateNumbers,
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.filters.wins, "value", $event.target.value)
                      },
                      function ($event) {
                        return _vm.filters.wins.set()
                      },
                    ],
                  },
                }),
                _c("span", [_vm._v("enter a minimum desirable number")]),
              ]),
            ]
          ),
          _c(
            "ToggleElement",
            {
              attrs: { shown: _vm.filters.skills.shown, title: "STAKED" },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.skills, "shown", $event)
                },
              },
            },
            [
              _c("Checkbox", {
                staticClass: "filters-checkbox",
                attrs: { name: "active", label: "Active" },
                on: {
                  input: function ($event) {
                    return _vm.filters.skills.set()
                  },
                },
                model: {
                  value: _vm.filters.skills.active,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.skills, "active", $$v)
                  },
                  expression: "filters.skills.active",
                },
              }),
              _c("Checkbox", {
                staticClass: "filters-checkbox",
                attrs: { name: "passive", label: "Passive" },
                on: {
                  input: function ($event) {
                    return _vm.filters.skills.set()
                  },
                },
                model: {
                  value: _vm.filters.skills.passive,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.skills, "passive", $$v)
                  },
                  expression: "filters.skills.passive",
                },
              }),
            ],
            1
          ),
          _c(
            "ToggleElement",
            {
              attrs: { shown: _vm.filters.booster.shown, title: "BOOSTER" },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.booster, "shown", $event)
                },
              },
            },
            [
              _c("Checkbox", {
                staticClass: "filters-checkbox",
                attrs: { name: "active", label: "2X" },
                on: {
                  input: function ($event) {
                    return _vm.filters.booster.set()
                  },
                },
                model: {
                  value: _vm.filters.booster.two,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.booster, "two", $$v)
                  },
                  expression: "filters.booster.two",
                },
              }),
              _c("Checkbox", {
                staticClass: "filters-checkbox",
                attrs: { name: "passive", label: "4X" },
                on: {
                  input: function ($event) {
                    return _vm.filters.booster.set()
                  },
                },
                model: {
                  value: _vm.filters.booster.four,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.booster, "four", $$v)
                  },
                  expression: "filters.booster.four",
                },
              }),
            ],
            1
          ),
          _c(
            "ToggleElement",
            {
              attrs: {
                shown: _vm.filters.haracteristics.shown,
                title: "SKILLS",
              },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.haracteristics, "shown", $event)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "filters__haracteristic" },
                [
                  _c("div", { staticClass: "filters__haracteristic-title" }, [
                    _vm._v("choose a range from 1 to 100"),
                  ]),
                  _vm._l(_vm.filters.haracteristics.options, function (item) {
                    return _c(
                      "div",
                      { key: item.name, staticClass: "item item--row" },
                      [
                        _c("Checkbox", {
                          staticClass: "filters-checkbox",
                          attrs: { name: item.name, label: item.name },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        }),
                        _c("Range", {
                          attrs: { isEnabled: item.value },
                          on: {
                            input: function ($event) {
                              return _vm.filters.haracteristics.set()
                            },
                          },
                          model: {
                            value: item.range,
                            callback: function ($$v) {
                              _vm.$set(item, "range", $$v)
                            },
                            expression: "item.range",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ]
          ),
          _c(
            "ToggleElement",
            {
              attrs: {
                shown: _vm.filters.experience_gte.shown,
                title: "EXPERIENCE",
              },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.experience_gte, "shown", $event)
                },
              },
            },
            [
              _c("div", { staticClass: "item item--row item--mb" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.experience_gte.value,
                      expression: "filters.experience_gte.value",
                    },
                  ],
                  staticClass: "number-input",
                  class: {
                    "number-input--empty": !_vm.filters.experience_gte.value,
                  },
                  attrs: { type: "number" },
                  domProps: { value: _vm.filters.experience_gte.value },
                  on: {
                    keypress: _vm.validateNumbers,
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filters.experience_gte,
                          "value",
                          $event.target.value
                        )
                      },
                      function ($event) {
                        return _vm.filters.experience_gte.set()
                      },
                    ],
                  },
                }),
                _c("span", [_vm._v("enter a minimum experience")]),
              ]),
              _c("div", { staticClass: "item item--row item--mb" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.experience_lte.value,
                      expression: "filters.experience_lte.value",
                    },
                  ],
                  staticClass: "number-input",
                  class: {
                    "number-input--empty": !_vm.filters.experience_lte.value,
                  },
                  attrs: { type: "number" },
                  domProps: { value: _vm.filters.experience_lte.value },
                  on: {
                    keypress: _vm.validateNumbers,
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filters.experience_lte,
                          "value",
                          $event.target.value
                        )
                      },
                      function ($event) {
                        return _vm.filters.experience_lte.set()
                      },
                    ],
                  },
                }),
                _c("span", [_vm._v("enter a maximum experience number")]),
              ]),
            ]
          ),
          _c(
            "ToggleElement",
            {
              attrs: {
                shown: _vm.filters.head_color.shown,
                title: "HEAD COLOR",
              },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.head_color, "shown", $event)
                },
              },
            },
            [
              _c("ColorPicker", {
                model: {
                  value: _vm.filters.head_color.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.head_color, "value", $$v)
                  },
                  expression: "filters.head_color.value",
                },
              }),
            ],
            1
          ),
          _c(
            "ToggleElement",
            {
              attrs: {
                shown: _vm.filters.body_color.shown,
                title: "BODY COLOR",
              },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.body_color, "shown", $event)
                },
              },
            },
            [
              _c("ColorPicker", {
                model: {
                  value: _vm.filters.body_color.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.body_color, "value", $$v)
                  },
                  expression: "filters.body_color.value",
                },
              }),
            ],
            1
          ),
          _c(
            "ToggleElement",
            {
              attrs: {
                shown: _vm.filters.legs_color.shown,
                title: "LEG COLOR",
              },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.legs_color, "shown", $event)
                },
              },
            },
            [
              _c("ColorPicker", {
                model: {
                  value: _vm.filters.legs_color.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.legs_color, "value", $$v)
                  },
                  expression: "filters.legs_color.value",
                },
              }),
            ],
            1
          ),
          _c(
            "ToggleElement",
            {
              attrs: {
                shown: _vm.filters.hands_color.shown,
                title: "HAND COLOR",
              },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.hands_color, "shown", $event)
                },
              },
            },
            [
              _c("ColorPicker", {
                model: {
                  value: _vm.filters.hands_color.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.hands_color, "value", $$v)
                  },
                  expression: "filters.hands_color.value",
                },
              }),
            ],
            1
          ),
          _c(
            "ToggleElement",
            {
              attrs: {
                shown: _vm.filters.weapon_color.shown,
                title: "WEAPON COLOR",
              },
              on: {
                "update:shown": function ($event) {
                  return _vm.$set(_vm.filters.weapon_color, "shown", $event)
                },
              },
            },
            [
              _c("ColorPicker", {
                model: {
                  value: _vm.filters.weapon_color.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.weapon_color, "value", $$v)
                  },
                  expression: "filters.weapon_color.value",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filters-apply-btns" },
            [
              _vm.isFilterChanged
                ? _c("btn", {
                    attrs: {
                      type: "icon-gradient",
                      text: "Apply filters",
                      "text-size": 13,
                    },
                    on: { click: _vm.applyFilter },
                  })
                : _vm._e(),
              !_vm.isFiltersEmpty
                ? _c("btn", {
                    attrs: {
                      type: "icon-gradient",
                      text: "Clear filters",
                      "text-size": 13,
                    },
                    on: { click: _vm.clearFilter },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }