<template>
    <div
        class="custom-select"
        :style="{color}"
        :tabindex="tabindex"
        @blur="open = false"
    >
        <div
            class="selected"
            :class="{open: open}"
            @click="open = !open"
        >
            {{ selected }}
        </div>
        <div
            class="items"
            :class="{selectHide: !open}"
        >
            <div
                class="item"
                v-for="(option, i) of options"
                :key="i"
                :class="{'item--selected': option === selected}"
                @click="selected=option; open=false; $emit('input', option)"
            >
                <p>{{ option }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        options:{
            type: Array,
            required: true
        },
        tabindex:{
            type: Number,
            required: false,
            default: 0
        },
        default: {
            type: String,
            required: false,
            default: null
        },
        color: {
            type: String,
            required: false,
            default: null
        },
    },
    watch: {
        default(val) {
            this.selected = val;
        }
    },
    data() {
        return {
            selected: this.default ? this.default : this.options[0],
            open: false
        };
    },
    mounted(){
        this.$emit('input', this.selected);
    }
};
</script>

<style lang="scss" scoped>

.custom-select {
    position: relative;
    width: 140px;
    text-align: left;
    outline: none;
    height: 47px;
    line-height: 47px;
    color: #FFCC13;
}

.selected {
    height: 100%;
    background-color: transparent;
    border: 1px solid #D9D9D9;
    color: currentColor;
    padding-left: 8px;
    cursor: pointer;
    user-select: none;
}

.selected:after {
    position: absolute;
    content: "";
    top: 22px;
    right: 10px;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-color: #fff transparent transparent transparent;
}

.items {
    border: 1px solid #D9D9D9;
    overflow: hidden;
    position: absolute;
    background-color: #1F213A;
    left: 0;
    right: 0;
    z-index: 20;
    max-height: 500px;
    overflow-y: auto;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }
}

.item{
    color: #BFBFBF;
    padding-left: 8px;
    cursor: pointer;
    user-select: none;

    &--selected {
        color: currentColor;
    }
}

.item:hover{
    background-color: #B68A28;
}

.selectHide {
    display: none;
}
</style>
