var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "custom-select",
      style: { color: _vm.color },
      attrs: { tabindex: _vm.tabindex },
      on: {
        blur: function ($event) {
          _vm.open = false
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "selected",
          class: { open: _vm.open },
          on: {
            click: function ($event) {
              _vm.open = !_vm.open
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.selected) + " ")]
      ),
      _c(
        "div",
        { staticClass: "items", class: { selectHide: !_vm.open } },
        _vm._l(_vm.options, function (option, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "item",
              class: { "item--selected": option === _vm.selected },
              on: {
                click: function ($event) {
                  _vm.selected = option
                  _vm.open = false
                  _vm.$emit("input", option)
                },
              },
            },
            [_c("p", [_vm._v(_vm._s(option))])]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }