var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Content",
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "div",
            { staticClass: "shop-wrapper" },
            [
              _vm.isFullWidth
                ? _c("Filters", { on: { filterApply: _vm.filterApply } })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "shop-wrapper__main" },
                [
                  !_vm.isFullWidth
                    ? _c(
                        "div",
                        { staticClass: "shop-settings" },
                        [
                          _c("Filters", {
                            on: { filterApply: _vm.filterApply },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "market-place-header",
                    [
                      !_vm.GET_IS_MOBILE
                        ? _c("template", { slot: "actions" }, [
                            _c(
                              "div",
                              { staticClass: "btns-boosters" },
                              _vm._l(_vm.boosters, function (booster) {
                                return _c("BtnTwice", {
                                  key: booster.id,
                                  attrs: {
                                    leftText: `${booster.name} BOOSTER`,
                                    rightText: `BUY ${_vm.toLocaleFormat(
                                      booster.price
                                    )} MF`,
                                    hoverText: `BUY ${_vm.toLocaleFormat(
                                      booster.usd_price
                                    )} $`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.buyBoosterHandler(booster.id)
                                    },
                                  },
                                })
                              }),
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "pagination-content" },
                    [
                      _c(
                        "div",
                        { staticClass: "sort-select" },
                        [
                          _c("span", { staticClass: "sort-select-icon" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "23",
                                  height: "13",
                                  viewBox: "0 0 23 13",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M0.697266 0.545456H22.5154L21.3033 2.9697H0.697266V0.545456Z",
                                    fill: "#262626",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M0.697266 5.39394H20.0912L18.8791 7.81818H0.697266V5.39394Z",
                                    fill: "#262626",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M0.697266 10.2424H17.667L16.4548 12.6667H0.697266V10.2424Z",
                                    fill: "#262626",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("Select", {
                            attrs: {
                              color: "white",
                              options: _vm.getSortOptions,
                              default: _vm.getSort,
                            },
                            on: { input: _vm.setSort },
                          }),
                        ],
                        1
                      ),
                      _c("Pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.pageSize < _vm.SHOP_CHARACTERS_COUNT,
                            expression: "pageSize < SHOP_CHARACTERS_COUNT",
                          },
                        ],
                        attrs: {
                          currentPage: Number(_vm.currentPage),
                          pageCount: _vm.pagesCount,
                          navigationEnabled: true,
                        },
                        on: { clickHandler: _vm.pageHandler },
                      }),
                    ],
                    1
                  ),
                  _vm.isListLoading
                    ? _c("Loader")
                    : _c(
                        "div",
                        { staticClass: "nft-cards" },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "slide-fade", mode: "out-in" } },
                            [
                              _vm.isMounted
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "nft-cards__link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToUrl(
                                            "/marketplace/mint"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "nft-cards__link-text" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "nft-cards__link-plus",
                                            },
                                            [_vm._v("+")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "nft-cards__link-label",
                                            },
                                            [_vm._v("MINT FIGHTER")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._l(
                            _vm.shopCharactersFiltered,
                            function (character, i) {
                              return _c(
                                "shop-nft-card",
                                _vm._b(
                                  {
                                    key: i,
                                    staticClass: "nft-cards__item",
                                    attrs: {
                                      transitionDuration: i * 100,
                                      name: character.nft.name,
                                      seller: character.seller,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onCardClick(character)
                                      },
                                      "btn-click": function ($event) {
                                        return _vm.onBtnClick(character)
                                      },
                                    },
                                  },
                                  "shop-nft-card",
                                  character.nft.mf_attributes,
                                  false
                                )
                              )
                            }
                          ),
                        ],
                        2
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c("approve-popup", {
            ref: "approvePopup",
            attrs: {
              "action-btn-text": "BUY",
              successMsg:
                "You have approved a sufficient number of MF tokens. Now you can buy the character.",
            },
            on: { "action-btn-click": _vm.buyCharacter },
          }),
          _c("transaction-success-popup", { ref: "transactionSuccessPopup" }),
          _vm.isProcessing
            ? _c("preloader", { attrs: { translucent: "", black: "" } })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }